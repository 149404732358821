import React from 'react';
import { Box, Button, Text, VStack, Image, useToast } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import config from '../config';

const Login = () => {
  const toast = useToast();

  const handleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        hd: 'mageplaza.com' // Restrict to specific domain
      });
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: 'Login Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box 
      height="70vh" 
      display="flex" 
      alignItems="center" 
      justifyContent="center"
      bg="gray.50"
    >
      <VStack spacing={8} p={8} bg="white" borderRadius="lg" boxShadow="lg">
        <Image src="/logo.png" alt="Logo" h="60px" objectFit="contain" />
        <Text fontSize="2xl" fontWeight="bold">Welcome to ChatGPT Clone</Text>
        <Text color="gray.600">Please sign in with your work email</Text>
        <Button
          leftIcon={<FcGoogle />}
          onClick={handleLogin}
          size="lg"
          w="full"
          colorScheme="gray"
        >
          Sign in with Google
        </Button>
        <Text fontSize="sm" color="gray.500">
          Only @mageplaza.com and @avadagroup.com domains are allowed
        </Text>
      </VStack>
    </Box>
  );
};

export default Login; 