import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Input,
  Button,
  VStack,
  HStack,
  Text,
  Flex,
  Container,
  IconButton,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  Avatar,
} from '@chakra-ui/react';
import { ChatIcon, AddIcon, EditIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { BsThreeDotsVertical } from 'react-icons/bs';
import axios from 'axios';
import config from '../config';
import { auth } from '../firebase';

const Chat = ({ user }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editTitle, setEditTitle] = useState('');
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose
  } = useDisclosure();
  const toast = useToast();

  // Custom renderer for code blocks
  const CodeBlock = ({ language, value }) => {
    return (
      <SyntaxHighlighter
        language={language}
        style={atomDark}
        customStyle={{
          margin: '1em 0',
          borderRadius: '0.375rem',
        }}
      >
        {value}
      </SyntaxHighlighter>
    );
  };

  const fetchChats = async () => {
    try {
      setError(null);
      const response = await axios.get(`${config.apiUrl}/api/chats`);
      setChatHistory(response.data);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setError('Failed to load chats');
      toast({
        title: 'Error loading chats',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleNewChat = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${config.apiUrl}/api/chats`);
      setChatHistory(prev => [response.data, ...prev]);
      setCurrentChat(response.data);
      setMessages([]);
    } catch (error) {
      console.error('Error creating new chat:', error);
      toast({
        title: 'Error creating new chat',
        description: 'Please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatSelect = (chat) => {
    if (currentChat?._id === chat._id) return;
    setCurrentChat(chat);
    setMessages(chat.messages || []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    setInput('');
    setIsLoading(true);

    try {
      // Get fresh ID token
      const idToken = await auth.currentUser.getIdToken();
      
      if (!currentChat) {
        const newChatResponse = await axios.post(`${config.apiUrl}/api/chats`, {}, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });
        setCurrentChat(newChatResponse.data);
        setChatHistory(prev => [newChatResponse.data, ...prev]);
      }

      const response = await axios.post(`${config.apiUrl}/chat`, {
        messages: newMessages,
        chatId: currentChat?._id
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      const updatedMessages = [...newMessages, response.data];
      setMessages(updatedMessages);

      // Cập nhật title cho chat mới
      if (currentChat?.title === 'New Chat') {
        const newTitle = userMessage.content.substring(0, 30) + (userMessage.content.length > 30 ? "..." : "");
        const updatedChat = await axios.put(`${config.apiUrl}/api/chats/${currentChat._id}`, {
          title: newTitle
        }, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });
        
        // Cập nhật chatHistory với title mới
        setChatHistory(prev => prev.map(chat => 
          chat._id === currentChat._id ? updatedChat.data : chat
        ));
        setCurrentChat(updatedChat.data);
      } else {
        // Cập nhật messages trong chatHistory
        setChatHistory(prev => prev.map(chat => 
          chat._id === currentChat._id 
            ? { ...chat, messages: updatedMessages }
            : chat
        ));
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error sending message',
        description: 'Please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTitle = async () => {
    if (!editTitle.trim()) {
      toast({
        title: 'Title cannot be empty',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(`${config.apiUrl}/api/chats/${currentChat._id}`, {
        title: editTitle
      });
      setChatHistory(prev => prev.map(chat => 
        chat._id === currentChat._id ? response.data : chat
      ));
      setCurrentChat(response.data);
      onClose();
      toast({
        title: 'Chat renamed successfully',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      console.error('Error updating chat title:', error);
      toast({
        title: 'Error renaming chat',
        description: 'Please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await axios.delete(`${config.apiUrl}/api/chats/${chatId}`);
      setChatHistory(prev => prev.filter(chat => chat._id !== chatId));
      if (currentChat?._id === chatId) {
        setCurrentChat(null);
        setMessages([]);
      }
      toast({
        title: 'Chat deleted successfully',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      console.error('Error deleting chat:', error);
      toast({
        title: 'Error deleting chat',
        description: 'Please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Flex h="100vh">
      {/* Sidebar */}
      <Box w="260px" bg="gray.900" color="white" p={4}>
        <VStack spacing={4} align="stretch">
          <Button
            leftIcon={<AddIcon />}
            bg="gray.700"
            color="white"
            _hover={{ bg: 'gray.600' }}
            w="full"
            onClick={handleNewChat}
            isLoading={isLoading}
          >
            New chat
          </Button>

          <Divider />

          {/* User Profile */}
          <Menu>
            <MenuButton
              as={Button}
              w="full"
              variant="ghost"
              color="white"
              _hover={{ bg: 'gray.700' }}
            >
              <HStack spacing={3}>
                <Avatar size="sm" src={user.picture} name={user.name} />
                <Text isTruncated>{user.name}</Text>
              </HStack>
            </MenuButton>
            <MenuList bg="gray.800">
              <MenuItem
                icon={<SettingsIcon />}
                onClick={onProfileOpen}
                _hover={{ bg: 'gray.700' }}
                bg="gray.800"
                color="white"
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                _hover={{ bg: 'gray.700' }}
                bg="gray.800"
                color="white"
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>

          <Divider />

          {/* Chat History */}
          <VStack align="stretch" spacing={2} flex={1} overflowY="auto">
            {error && (
              <Text color="red.300" textAlign="center" fontSize="sm">
                {error}
              </Text>
            )}
            {chatHistory.length === 0 && !error && (
              <Text color="gray.500" textAlign="center" fontSize="sm">
                No chats yet
              </Text>
            )}
            {chatHistory.map((chat) => (
              <Flex
                key={chat._id}
                p={2}
                align="center"
                cursor="pointer"
                borderRadius="md"
                bg={currentChat?._id === chat._id ? 'gray.700' : 'transparent'}
                _hover={{ bg: 'gray.700' }}
                onClick={() => handleChatSelect(chat)}
              >
                <ChatIcon mr={3} />
                <Text flex={1} isTruncated>
                  {chat.title}
                </Text>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<BsThreeDotsVertical />}
                    variant="ghost"
                    size="sm"
                    _hover={{ bg: 'gray.700' }}
                  />
                  <MenuList bg="gray.800">
                    <MenuItem
                      icon={<EditIcon />}
                      onClick={() => {
                        setEditTitle(chat.title);
                        setCurrentChat(chat);
                        onOpen();
                      }}
                      _hover={{ bg: 'gray.700' }}
                      bg="gray.800"
                      color="white"
                    >
                      Rename
                    </MenuItem>
                    <MenuItem
                      icon={<DeleteIcon />}
                      onClick={() => handleDeleteChat(chat._id)}
                      _hover={{ bg: 'gray.700' }}
                      bg="gray.800"
                      color="white"
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ))}
          </VStack>
        </VStack>
      </Box>

      {/* Main Chat Area */}
      <Flex flex={1} direction="column" bg="gray.50">
        {!currentChat ? (
          <Flex flex={1} justify="center" align="center" direction="column">
            <Text color="gray.500" mb={4}>Select a chat or start a new one</Text>
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              onClick={handleNewChat}
              isLoading={isLoading}
            >
              New Chat
            </Button>
          </Flex>
        ) : (
          <>
            <Box flex={1} overflowY="auto" p={4} bg="white">
              {messages.map((message, index) => (
                <Box
                  key={index}
                  bg={message.role === 'user' ? 'white' : 'gray.50'}
                  p={6}
                  borderBottom="1px"
                  borderColor="gray.100"
                >
                  <Container maxW="container.md">
                    <HStack align="flex-start" spacing={4}>
                      <Box
                        w="30px"
                        h="30px"
                        borderRadius="md"
                        bg={message.role === 'user' ? 'blue.500' : 'green.500'}
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="sm"
                      >
                        {message.role === 'user' ? 'U' : 'AI'}
                      </Box>
                      <Box flex={1}>
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            code({ node, inline, className, children, ...props }) {
                              const match = /language-(\w+)/.exec(className || '');
                              return !inline && match ? (
                                <CodeBlock
                                  language={match[1]}
                                  value={String(children).replace(/\n$/, '')}
                                  {...props}
                                />
                              ) : (
                                <code className={className} {...props}>
                                  {children}
                                </code>
                              );
                            },
                            p: ({ children }) => <Text mb={2}>{children}</Text>,
                            h1: ({ children }) => (
                              <Text fontSize="2xl" fontWeight="bold" mb={4}>
                                {children}
                              </Text>
                            ),
                            h2: ({ children }) => (
                              <Text fontSize="xl" fontWeight="bold" mb={3}>
                                {children}
                              </Text>
                            ),
                            h3: ({ children }) => (
                              <Text fontSize="lg" fontWeight="bold" mb={2}>
                                {children}
                              </Text>
                            ),
                            ul: ({ children }) => (
                              <Box as="ul" pl={4} mb={2}>
                                {children}
                              </Box>
                            ),
                            ol: ({ children }) => (
                              <Box as="ol" pl={4} mb={2}>
                                {children}
                              </Box>
                            ),
                            li: ({ children }) => (
                              <Box as="li" mb={1}>
                                {children}
                              </Box>
                            ),
                            blockquote: ({ children }) => (
                              <Box
                                borderLeft="4px"
                                borderColor="gray.300"
                                pl={4}
                                py={2}
                                my={4}
                                color="gray.600"
                              >
                                {children}
                              </Box>
                            ),
                            table: ({ children }) => (
                              <Box overflowX="auto">
                                <Box as="table" width="full" mb={4}>
                                  {children}
                                </Box>
                              </Box>
                            ),
                            th: ({ children }) => (
                              <Box
                                as="th"
                                bg="gray.100"
                                px={4}
                                py={2}
                                textAlign="left"
                                fontWeight="bold"
                              >
                                {children}
                              </Box>
                            ),
                            td: ({ children }) => (
                              <Box as="td" borderTopWidth="1px" px={4} py={2}>
                                {children}
                              </Box>
                            ),
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      </Box>
                    </HStack>
                  </Container>
                </Box>
              ))}
              <div ref={messagesEndRef} />
            </Box>

            <Box p={4} borderTop="1px" borderColor="gray.200" bg="white">
              <Container maxW="container.md">
                <form onSubmit={handleSubmit}>
                  <HStack spacing={4}>
                    <Input
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Send a message..."
                      size="lg"
                      bg="white"
                      border="1px"
                      borderColor="gray.300"
                      _hover={{ borderColor: 'gray.400' }}
                      _focus={{ borderColor: 'blue.500', boxShadow: 'none' }}
                      disabled={isLoading}
                    />
                    <IconButton
                      type="submit"
                      icon={<ChatIcon />}
                      colorScheme="blue"
                      size="lg"
                      isLoading={isLoading}
                      aria-label="Send message"
                    />
                  </HStack>
                </form>
                <Text fontSize="xs" color="gray.500" mt={2} textAlign="center">
                  ChatGPT Customized by Brian in 2 hours
                </Text>
              </Container>
            </Box>
          </>
        )}
      </Flex>

      {/* Profile Modal */}
      <Modal isOpen={isProfileOpen} onClose={onProfileClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="center">
              <Avatar
                size="2xl"
                src={user.picture}
                name={user.name}
              />
              <Text fontSize="xl" fontWeight="bold">
                {user.name}
              </Text>
              <Text color="gray.600">
                {user.email}
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleLogout}>
              Logout
            </Button>
            <Button variant="ghost" onClick={onProfileClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Rename Chat Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              placeholder="Enter new title"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleEditTitle}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Chat;