import { ChakraProvider } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import Chat from './components/Chat';
import Login from './components/Login';
import axios from 'axios';
import config from './config';
import { auth } from './firebase';
import { onAuthStateChanged, getRedirectResult } from 'firebase/auth';
import { useToast } from '@chakra-ui/react';

axios.defaults.withCredentials = true;

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    // Xử lý kết quả redirect
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          // Kiểm tra domain email
          const email = result.user.email;
          if (!email.endsWith('@mageplaza.com') && !email.endsWith('@avadagroup.com')) {
            auth.signOut();
            throw new Error('Unauthorized email domain');
          }
        }
      })
      .catch((error) => {
        console.error('Redirect error:', error);
        toast({
          title: 'Authentication Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const idToken = await firebaseUser.getIdToken();
          // Fetch additional user data from your backend if needed
          const response = await fetch(`${config.apiUrl}/api/users/me`, {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          });
          const userData = await response.json();
          setUser(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [toast]);

  if (loading) {
    return null;
  }

  return (
    <ChakraProvider>
      {user ? <Chat user={user} /> : <Login />}
    </ChakraProvider>
  );
}

export default App;